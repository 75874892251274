import React from 'react'

import { Link } from 'gatsby'

function BottomNav(props) {
  return (
    <section
      className={
        'bottom-nav ' + (props.additionalClass ? props.additionalClass : '')
      }
    >
      {props.heading ? (
        <h2
          className="bottom-nav__heading text--3 
                        underline"
        >
          {props.heading}
        </h2>
      ) : null}

      <div className="bottom-nav__content-wrapper">
        <div className="bottom-nav-content">
          <nav className="bottom-nav__nav">
            <ul className="bottom-nav__list">
              <li className="bottom-nav__item">
                <Link
                  to={props.firstLink}
                  className="bottom-nav__link
                               text--3"
                >
                  {props.firstHeading}

                  {props.firstSubHeading ? (
                    <span
                      className="bottom-nav__sub-title
                                  text--5"
                    >
                      {props.firstSubHeading}
                    </span>
                  ) : null}
                </Link>
              </li>
              <li className="bottom-nav__item">
                <Link
                  to={props.secondLink}
                  className="bottom-nav__link
                              text--3"
                >
                  {props.secondHeading}

                  {props.secondSubHeading ? (
                    <span
                      className="bottom-nav__sub-title
                                  text--5"
                    >
                      {props.secondSubHeading}
                    </span>
                  ) : null}
                </Link>
              </li>

              {props.isServicesPage ? (
                <li className="bottom-nav__item">
                  <Link
                    to="/services/hire-developers"
                    className="bottom-nav__link
                                  text--3"
                  >
                    No-Gruha Developers
                    <span
                      className="bottom-nav__sub-title
                                      text--5"
                    >
                      Let's get started!
                    </span>
                  </Link>
                </li>
              ) : null}
            </ul>
          </nav>
        </div>
      </div>
    </section>
  )
}

export default BottomNav
