import React from 'react'

import SEO from '../../components/seo'

import Layout from '../../components/layout'
import BottomNav from '../../components/BottomNav/BottomNav'

const ServicesPage = () => (
  <Layout>
    <SEO
      title="Services"
      keywords={[
        `Emersoft`,
        `application`,
        `react`,
        `London`,
        `digital agency`,
      ]}
    />

    <BottomNav
      additionalClass="bottom-nav--services-page"
      heading="Services"
      firstLink="/services/websites"
      firstHeading="Websites/Design"
      firstSubHeading="Let's get started!"
      secondLink="/services/ai"
      secondHeading="AI Apps Development"
      secondSubHeading="Let's get stared!"
      isServicesPage="true"
    />
  </Layout>
)

export default ServicesPage
